import logo from './logo.svg';
import './App.css';
import Navbar from './Components/Navbar/Navbar';

function App() {
  return (
    <div className="App">
      
      <header className="App-header">
        <img src={logo} height="50" width="50" className="App-logo" alt="logo" />
        <Navbar/>
        
      </header>
    </div>
  );
}

export default App;
